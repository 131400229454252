import React, { useCallback, useEffect, useState } from "react";
import PostTypeNavBar from "../../../components/post-type-nav-bar";
import {
  useAddIndustry,
  useIndustryDetails,
  useUpdateIndustry,
} from "../../../react-query/query-hooks/industryQuery.hooks";
import { useLocation } from "react-router-dom";
import useNotiStack from "../../../custom-hooks/useNotiStack";
import useRedirect from "../../../custom-hooks/useRedirect";

const tabItems = [
  { name: "Create", route: "industry/create" },
  { name: "Published", route: "industry/published" },
  { name: "Draft", route: "industry/draft" },
  { name: "Trash", route: "industry/trash" },
];

const CreateEditIndustry = () => {
  const { pathname } = useLocation();
  const { toastSuccess, toastError } = useNotiStack();
  const redirectTo = useRedirect();

  const [coverImg, setCoverImg] = useState(null);
  const [industryId, setIndustryId] = useState("");
  const [isPreview, setIsPreview] = useState(false);
  const [industryData, setIndustryData] = useState({
    industry_name: "",
    cagr: "",
    size_in_india: "",
    size_in_global: "",
    big_players: "",
  });

  const {
    data: industryDetails,
    isLoading: industryDetailsLoader,
    refetch,
  } = useIndustryDetails(
    pathname?.split("/")?.at(-1),
    !!pathname?.split("/")?.at(-1),
    (response) => {
      const {
        id,
        industry_name,
        cagr,
        size_in_india,
        size_in_global,
        big_players,
        image,
        STATUS,
      } = response ?? {};
      setIndustryData({
        industry_name,
        cagr,
        size_in_india,
        size_in_global,
        big_players,
      });
      setCoverImg(image);
    }
  );
  const { mutate: addIndustry } = useAddIndustry();
  const { mutate: updateIndustry } = useUpdateIndustry();

  const getCoverImage = (img) => setCoverImg(img);
  const removeImage = () => setCoverImg(null);
  const getIndustryData = useCallback(
    (field, value) => setIndustryData({ ...industryData, [field]: value }),
    [industryData]
  );
  const addPostHandler = (type = "publish") => {
    const { industry_name, cagr, size_in_india, size_in_global, big_players } =
      industryData ?? {};
    if (!!industryId) {
      if (type == "publish") {
        const redirectionUrl =
          industryDetails?.STATUS == "1"
            ? "industry/published"
            : industryDetails?.STATUS == "2"
            ? "industry/draft"
            : "industry/trash";
        const formData = new FormData();
        const payload = {};
        formData.append("industryId", `${industryDetails?.id}` ?? "");
        payload.industryId = `${industryDetails?.id}`;
        if (!!industry_name) {
          formData.append("industryName", industry_name);
          payload.industryName = industry_name;
        }
        if (!!cagr) {
          formData.append("cagr", cagr);
          payload.cagr = cagr;
        }
        if (typeof coverImg != "string") {
          formData.append("image", coverImg);
          payload.image = coverImg;
        }
        if (!!size_in_india) {
          formData.append("sizeInIndia", size_in_india);
          payload.sizeInIndia = size_in_india;
        }
        if (!!size_in_global) {
          formData.append("sizeInGlobal", size_in_global);
          payload.sizeInGlobal = size_in_global;
        }
        if (!!big_players) {
          formData.append("bigPlayers", big_players);
          payload.bigPlayers = big_players;
        }
        updateIndustry(formData, {
          onSuccess: () => {
            toastSuccess("Industry updated successfully");
            redirectTo(redirectionUrl);
          },
          onError: () => {
            toastError("Something went wrong please try again later.");
          },
        });
      } else {
        setIndustryData({
          industry_name: "",
          cagr: "",
          size_in_india: "",
          size_in_global: "",
          big_players: "",
        });
        setCoverImg(null);
        setIsPreview(false);
        setIndustryId('')
        redirectTo("industry/create");
      }
    } else {
      if (
        !!industry_name &&
        !!cagr &&
        !!size_in_india &&
        !!size_in_global &&
        !!big_players
      ) {
        const formData = new FormData();
        formData.append("industry_name", industry_name);
        formData.append("cagr", cagr);
        formData.append("image", coverImg);
        formData.append("size_in_india", size_in_india);
        formData.append("size_in_global", size_in_global);
        formData.append("big_players", big_players);
        formData.append("STATUS", type == "publish" ? "1" : "2");
        addIndustry(formData, {
          onSuccess: () => {
            toastSuccess("Infographic updated successfully");
            redirectTo(
              type == "publish" ? "industry/published" : "industry/draft"
            );
            // redirectTo(redirectionUrl);
          },
          onError: () => {
            toastError("Something went wrong please try again later.");
          },
        });
      }
    }
  };
  useEffect(() => {
    if (pathname?.includes("update")) {
      let urlPram = pathname?.split("/") ?? [];
      if (urlPram?.length > 0 && parseInt(urlPram?.at(-1)) > 0) {
        setIndustryId(urlPram?.at(-1));
        refetch();
      }
    }
  }, [pathname]);
  return (
    <>
      <PostTypeNavBar tabItems={tabItems} />
      <div className="rounded-lg m-2 md:m-8 p-4 bg-gradient-to-r from-green-50 to-cyan-200">

        <h2 className="text-3xl text-gray-700 font-bold mb-5 text-left">
            {!!industryId ? "Edit" : "Add"} Industry
          </h2>
        <div class="grid grid-cols-3 gap-4">
          <div className="col-span-2">
            <div className="flex flex-wrap justify-start gap-4 mb-4">
              <label class="grow block">
                <span class="block text-sm font-medium text-slate-700 text-left">
                  Industry name
                </span>
                <input
                  type="text"
                  name="startup_name"
                  class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                  placeholder="Ex: Fintech"
                  value={industryData?.industry_name}
                  onChange={(e) =>
                    getIndustryData("industry_name", e.target.value)
                  }
                />
              </label>
              <label class="grow block">
                <span class="block text-sm font-medium text-slate-700 text-left">
                  CAGR
                </span>
                <input
                  type="text"
                  name="startup_link"
                  class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                  placeholder="Ex: 10%"
                  value={industryData?.cagr}
                  onChange={(e) => getIndustryData("cagr", e.target.value)}
                />
              </label>
            </div>
            <div className="flex flex-wrap justify-start gap-4 mb-4">
              <label class="grow block">
                <span class="block text-sm font-medium text-slate-700 text-left">
                  Size in India
                </span>
                <input
                  type="text"
                  name="legal_name"
                  class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                  placeholder="Ex: 1212121"
                  value={industryData?.size_in_india}
                  onChange={(e) =>
                    getIndustryData("size_in_india", e.target.value)
                  }
                />
              </label>
              <label class="grow block">
                <span class="block text-sm font-medium text-slate-700 text-left">
                  Size in Global
                </span>
                <input
                  type="text"
                  name="legal_name"
                  class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                  placeholder="Ex: 1212121"
                  value={industryData?.size_in_global}
                  onChange={(e) =>
                    getIndustryData("size_in_global", e.target.value)
                  }
                />
              </label>
            </div>
            <div className="flex flex-wrap justify-start gap-4 mb-4">
              <label class="grow block">
                <span class="block text-sm font-medium text-slate-700 text-left">
                  Big Players
                </span>
                <input
                  type="text"
                  name="legal_name"
                  class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                  placeholder="Ex: BYJUS"
                  value={industryData?.big_players}
                  onChange={(e) =>
                    getIndustryData("big_players", e.target.value)
                  }
                />
              </label>
            </div>
          </div>
          {!!coverImg ? (
            <div>
              <img
                src={
                  typeof coverImg == "string"
                    ? `https://www.talesoffinance.com/industry_images/${coverImg}`
                    : URL.createObjectURL(coverImg)
                }
                //   src={'https://drive.google.com/uc?export=view&id=1CIQgjiF6xXdwXrUcqMW-n6o5vvVQAZI5'}
                className="object-cover h-56 w-96 rounded-lg border-2 border-slate-950"
              />
              <div className="flex justify-center items-center gap-4 mt-2">
                {/* <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={changeImage}
          >
            Change Image
          </button> */}
                <button
                  type="button"
                  class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                  onClick={removeImage}
                >
                  Remove Image
                </button>
              </div>
            </div>
          ) : (
            <div className="">
              <label
                htmlFor="cover-photo"
                className="block text-sm text-left font-medium leading-6 text-gray-900"
              >
                Cover photo
              </label>
              <div className="mt-1 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                <div className="text-center">
                  <svg
                    class="mx-auto h-12 w-12 text-gray-300"
                    viewBox="0 0 24 24"
                    fill="white"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <span className="p-2">Upload a file</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                        onChange={(e) => getCoverImage(e.target.files[0])}
                      />
                    </label>
                    <p className="pl-1 ps-1">or drag and drop</p>
                  </div>
                  <p className="text-xs leading-5 text-gray-600">
                    PNG, JPG, GIF up to 10MB
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-end gap-4 m-2 md:m-8 p-4">
        <button
          className={`rounded-md bg-gray-400 p-1 px-2 text-white`}
          onClick={() => addPostHandler("draft")}
          // disabled={!!blogID ? false : buttonEnabler}
        >
          {!!industryId ? "Discart" : "Save as draft"}
          {/* Save as draft */}
        </button>
        <button
          className={`rounded-md bg-blue-500 p-1 px-2 text-white`}
          onClick={() => addPostHandler("publish")}
          // disabled={buttonEnabler}
        >
          {!!industryId ? "Update" : "Publish"}
          {/* Publish */}
        </button>
      </div>
    </>
  );
};

export default CreateEditIndustry;
