import React, { memo, useMemo } from "react";
import Select from "react-select";
import { Dialog, Transition } from "@headlessui/react";

const TosModal = ({ open, onClose, content }) => {
  const {
    id,
    startup_name,
    website_link,
    legal_name,
    industry,
    based_out_of,
    business_model,
    founded_in,
    employee_headcount,
    profile_summary,
    options,
    status,
    cover_photo,
  } = content ?? "";
  const optionalFormList = useMemo(
    () => (!!options ? JSON.parse(options) : []),
    [options]
  );
  const optionalListHandler = (optionType) => {
    console.log("optionType", optionType);
    const { label, list } = optionType ?? {};
    switch (label) {
      case "Founder Details":
        return (
          <div className="rounded-lg m-2 md:mx-8 px-4">
            {list?.length > 0 &&
              list?.map((_li) => (
                <>
                  <tr>
                    <td className="text-lg text-gray-700 font-semibold">
                      Team Member
                    </td>
                    <td>{_li?.team_member}</td>
                  </tr>
                  <tr>
                    <td className="text-lg text-gray-700 font-semibold">
                      Position
                    </td>
                    <td>{_li?.position}</td>
                  </tr>
                  <tr>
                    <td className="text-lg text-gray-700 font-semibold">
                      Profile Link
                    </td>
                    <td>{_li?.profile_ink}</td>
                  </tr>
                </>
              ))}
          </div>
        );

      case "Revenue":
        return (
          <div className="rounded-lg m-2 md:mx-8 px-4">
            {list?.length > 0 &&
              list?.map((_li) => (
                <tr>
                  <td className="text-lg text-gray-700 font-semibold">
                    Revenue Stream
                  </td>
                  <td>{`${
                    !!_li?.revenue_stream &&
                    _li?.revenue_stream?.length > 0 &&
                    _li?.revenue_stream?.map((_i) => _i?.label)
                  }`}</td>
                </tr>
              ))}
          </div>
        );

      case "Target Group":
        return (
          <div className="rounded-lg m-2 md:mx-8 px-4">
            {list?.length > 0 &&
              list?.map((_li) => (
                <>
                  <tr>
                    <td className="text-lg text-gray-700 font-semibold">
                      Customer Group
                    </td>
                    <td>{_li?.customer_group}</td>
                  </tr>
                  <tr>
                    <td className="text-lg text-gray-700 font-semibold">
                      Client Business Structure
                    </td>
                    <td>{_li?.client_business_structure}</td>
                  </tr>
                  <tr>
                    <td className="text-lg text-gray-700 font-semibold">
                      Geographical Segment
                    </td>
                    <td>{_li?.geographical_segment}</td>
                  </tr>
                  <tr>
                    <td className="text-lg text-gray-700 font-semibold">
                      Age Group
                    </td>
                    <td>{_li?.age_group}</td>
                  </tr>
                  <tr>
                    <td className="text-lg text-gray-700 font-semibold">
                      Income Bracket
                    </td>
                    <td>{_li?.income_bracket}</td>
                  </tr>
                  <tr>
                    <td className="text-lg text-gray-700 font-semibold">
                      User Belongs to
                    </td>
                    <td>{_li?.user_belongs_to}</td>
                  </tr>
                </>
              ))}
          </div>
        );

      case "Total Fundings(in Milllion)":
        return (
          <div className="rounded-lg m-2 md:mx-8 px-4">
            {list?.length > 0 &&
              list?.map((_li) => (
                <>
                  <tr>
                    <td className="text-lg text-gray-700 font-semibold">
                      Total Fundings
                    </td>
                    <td>{_li?.total_fundings}</td>
                  </tr>
                </>
              ))}
          </div>
        );

      case "Investor":
        return (
          <div className="rounded-lg m-2 md:mx-8 px-4">
            {list?.length > 0 &&
              list?.map((_li) => (
                <>
                  <tr>
                    <td className="text-lg text-gray-700 font-semibold">
                      Funded By
                    </td>
                    <td>{_li?.funded_by}</td>
                  </tr>
                </>
              ))}
          </div>
        );

      case "Round":
        return (
          <div className="rounded-lg m-2 md:mx-8 px-4">
            {list?.length > 0 &&
              list?.map((_li, index) => (
                <>
                  <p className="text-start font-bold textlg">
                    {label}-{index + 1}
                  </p>
                  <tr>
                    <td className="text-lg text-gray-700 font-semibold">
                      Date
                    </td>
                    <td>{_li?.date}</td>
                  </tr>
                  <tr>
                    <td className="text-lg text-gray-700 font-semibold">
                      Funds Raised (USD Mns)
                    </td>
                    <td>{_li?.funds_raised}</td>
                  </tr>
                  <tr>
                    <td className="text-lg text-gray-700 font-semibold">
                      Name of Investors
                    </td>
                    <td>{_li?.name_of_investors}</td>
                  </tr>
                </>
              ))}
          </div>
        );

      default:
        return <></>;
    }
  };
  console.log("content", options && JSON.parse(options), content);
  return (
    <>
      <Dialog open={open} onClose={onClose} className="relative z-50">
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        {/* Full-screen scrollable container */}
        <div className="fixed inset-0 w-screen overflow-y-auto">
          {/* Container to center the panel */}
          <div className="flex min-h-full items-center justify-center p-4">
            {/* The actual dialog panel  */}
            <Dialog.Panel className="mx-auto max-w-2xl rounded-lg bg-white">
              <img
                className="rounded-t-lg object-cover w-full h-full md:w-2/5 md:h-2/5 float-none md:float-left p-4"
                src={`https://www.talesoffinance.com/tos/${cover_photo}`}
                alt=""
              />
              {!!startup_name && (
                <h3 className="text-3xl md:text-5xl text-gray-700 font-bold mb-5 w-full m-4">
                  <a
                    href={!!website_link ? website_link : "javascript:void(0)"}
                  >
                    {startup_name}
                  </a>
                </h3>
              )}
              {!!legal_name && (
                <h4 className="text-lg text-gray-700 font-bold mb-5 w-full m-4">
                  <a
                    href={!!website_link ? website_link : "javascript:void(0)"}
                  >
                    {legal_name}
                  </a>
                </h4>
              )}
              {!!profile_summary && (
                <p className="text-gray-500 text-lg m-4">{profile_summary}</p>
              )}
              <div className="rounded-lg m-2 md:mx-8 px-4">
                <>
                {!!industry && (
                  <tr>
                    <td className="text-lg text-gray-700 font-semibold">
                      Industry :
                    </td>
                    <td>{`${JSON.parse(industry)?.map((_i) => _i.label)}`}</td>
                  </tr>
                )}
                {!!based_out_of && (
                  <tr>
                    <td className="text-lg text-gray-700 font-semibold">
                      Based out of :
                    </td>
                    <td>{`${JSON.parse(based_out_of)?.map(
                      (_i) => _i.label
                    )}`}</td>
                  </tr>
                )}
                {!!business_model && (
                  <tr>
                    <td className="text-lg text-gray-700 font-semibold">
                      Business Model :
                    </td>
                    <td>{`${JSON.parse(business_model)?.map(
                      (_i) => _i.label
                    )}`}</td>
                  </tr>
                )}
                {!!founded_in && (
                  <tr>
                    <td className="text-lg text-gray-700 font-semibold">
                      Founded in :
                    </td>
                    <td>{founded_in}</td>
                  </tr>
                )}
                {!!employee_headcount && (
                  <tr>
                    <td className="text-lg text-gray-700 font-semibold">
                      Employees:
                    </td>
                    <td>{`${JSON.parse(employee_headcount)?.map(
                      (_i) => _i.label
                    )}`}</td>
                  </tr>
                )}
                </>
              </div>

              {!!optionalFormList &&
                optionalFormList?.length > 0 &&
                optionalFormList?.map((_optForm) =>
                  _optForm?.isSelected ? (
                    <>{optionalListHandler(_optForm)}</>
                  ) : (
                    <></>
                  )
                )}
              {/* <div className="p-4">
                <table className="table-auto overflow-y-auto h-32 w-full">
                  <tbody>
                    {content.legally_known_as && (
                      <tr>
                        <td className="text-lg text-gray-700 font-semibold">
                          Legal name
                        </td>
                        <td>{content.legally_known_as}</td>
                      </tr>
                    )}
                    {content?.based_out_of && (
                      <tr>
                        <td className="text-lg text-gray-700 font-semibold">
                          Headquater
                        </td>
                        <td className="text-gray-500 text-lg">
                          {content?.based_out_of}
                        </td>
                      </tr>
                    )}
                    {content?.business_model && (
                      <tr>
                        <td className="text-lg text-gray-700 font-semibold">
                          Business model
                        </td>
                        <td className="text-gray-500 text-lg">
                          {content?.business_model}
                        </td>
                      </tr>
                    )}
                    {content?.founded_in && (
                      <tr>
                        <td className="text-lg text-gray-700 font-semibold">
                          Founding date
                        </td>
                        <td className="text-gray-500 text-lg">
                          {content?.founded_in}
                        </td>
                      </tr>
                    )}
                    {content?.employees_headcount && (
                      <tr>
                        <td className="text-lg text-gray-700 font-semibold">
                          Employee count
                        </td>
                        <td className="text-gray-500 text-lg ms-4">
                          {content?.employees_headcount.includes("<")
                            ? `more than ${
                                content?.employees_headcount.split("<")[1]
                              }`
                            : content?.employees_headcount}
                        </td>
                      </tr>
                    )}
                    {content?.revenue_stream && (
                      <tr>
                        <td className="text-lg text-gray-700 font-semibold">
                          Revenue stream
                        </td>
                        <td className="text-gray-500 text-lg">
                          {content?.revenue_stream}
                        </td>
                      </tr>
                    )}
                    {content?.customer_group && (
                      <tr>
                        <td className="text-lg text-gray-700 font-semibold">
                          Client segment
                        </td>
                        <td className="text-gray-500 text-lg">
                          {content?.customer_group}
                        </td>
                      </tr>
                    )}
                    {content?.client_business_structure && (
                      <tr>
                        <td className="text-lg text-gray-700 font-semibold">
                          Target companies
                        </td>
                        <td className="text-gray-500 text-lg">
                          {content?.client_business_structure}
                        </td>
                      </tr>
                    )}
                    {content?.geographical_segment && (
                      <tr>
                        <td className="text-lg text-gray-700 font-semibold">
                          Target companies
                        </td>
                        <td className="text-gray-500 text-lg">
                          {content?.geographical_segment}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div> */}

              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <span
                  type="button"
                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                  onClick={onClose}
                >
                  Close
                </span>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default memo(TosModal);
