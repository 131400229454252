import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { endpoints } from "../../api/api-end-points";
import {
  INDUSTRY_DETAILS,
  INDUSTRY_LIST,
  INDUSTRY_NAME_LIST,
} from "../query-keys/industryQuery.keys";
import axiosInstance from "../../api/axios-instance";

//   <------------------ADD INDUSTRY ------------------->
const addIndustry = async (body) => {
  const res = await axios.post(
    "https://www.talesoffinance.com/api/create-industry",
    body,
    {
      headers: {
        "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
      },
    }
  );
  return res;
};
export const useAddIndustry = () => useMutation(addIndustry);

//   <------------------ INDUSTRY LIST ------------------->
const getIndustryList = async (status) => {
  const res = await axiosInstance.get(
    `${endpoints.industry.industryList}${status ? `?status=${status}` : ""}`
  );
  return res;
};

export const useIndustryList = (
  status,
  enabled = false,
  onSuccess = () => {},
  onError = () => {}
) =>
  useQuery([INDUSTRY_LIST, status], () => getIndustryList(status), {
    onSuccess,
    onError,
    enabled,
    select: (data) => data?.data?.industries ?? [],
  });

//   <------------------ ALL INDUSTRY LIST ------------------->
const getIndustryNameList = async () => {
  const res = await axiosInstance.get(
    endpoints.industry.industryNameList
  );
  return res;
};

export const useIndustryNameList = (
  enabled = false,
  onSuccess = () => {},
  onError = () => {}
) =>
  useQuery([INDUSTRY_NAME_LIST], getIndustryNameList, {
    enabled,
    onSuccess,
    onError,
    select: (data) => data?.data ?? [],
  });

//   <------------------UPDATE INDUSTRY STATUS ------------------->
const updateIndustryStatus = async (body) => {
  const res = await axiosInstance.post(
    endpoints.industry.changeIndustryStatus,
    body
  );
  return res;
};
export const useUpdateIndustryStatus = () => useMutation(updateIndustryStatus);

//   <------------------ INFOGRAPHIC DETAILS ------------------->
const getIndustryDetails = async (id) => {
  const res = await axiosInstance.get(
    `${endpoints.industry.getIndustryById}${id ? `/${id}` : ""}`
  );
  return res;
};

export const useIndustryDetails = (
  id,
  enabled = false,
  onSuccess = () => {},
  onError = () => {}
) =>
  useQuery([INDUSTRY_DETAILS, id], () => getIndustryDetails(id), {
    onSuccess,
    onError,
    enabled: false,
    select: (data) => data?.data?.industry ?? [],
  });

//   <------------------UPDATE INFOGRAPHIC ------------------->
const updateIndustryPost = async (body) => {
  const res = await axios.post(
    "https://www.talesoffinance.com/api/updateIndustry",
    body,
    {
      headers: {
        "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
      },
    }
  );
  return res;
};
export const useUpdateIndustry = () => useMutation(updateIndustryPost);
