import CmsLayout from "../../../layouts/cms-layout";
import CreateEditIndustry from "../pages/create-edit-industry";
import ListingIndustry from "../pages/listing-industry";



export const IndustryRouter = [
  {
    path: "/industry",
    element:<CmsLayout/>,
    children: [
      { path: "create", element: <CreateEditIndustry /> },
      { path: "update/:id", element: <CreateEditIndustry /> },
      { path: "published", element: <ListingIndustry /> },
      { path: "draft", element: <ListingIndustry /> },
      { path: "trash", element: <ListingIndustry /> },
    ],
  },
];