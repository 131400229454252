import React, { Suspense, memo, useEffect } from "react";
import { Outlet } from "react-router-dom";
import CmsSideNavBar from "../components/cms-side-nav-bar";
import useRedirect from "../custom-hooks/useRedirect";
import { usePolling } from "../react-query/query-hooks/loginQuery.hooks";
import useNotiStack from "../custom-hooks/useNotiStack";

const CmsLayout = ({ props }) => {
  const redirectTo = useRedirect();
  const { toastSuccess, toastError } = useNotiStack();
  console.log("props", props);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      redirectTo("");
    }
  }, []);
  const { data } = usePolling(
    () => {},
    () => {
      toastError(
        `We're encountering difficulty connecting to our services. Please take a moment to double-check your internet connection. Ensure that you're connected to a stable network. After confirming your internet connection, kindly restart your web browser. Sometimes a simple restart can resolve unexpected issues.`
      );
    }
  );
  return (
    <>
      <CmsSideNavBar />
      <div className="w-100">
        <div className="h-100">
          <Suspense fallback={<p>Loading.....</p>}>
            <Outlet></Outlet>
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default memo(CmsLayout);
