import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { endpoints } from "../../api/api-end-points";
import axiosInstance from "../../api/axios-instance";

const userLogin = async (body) => {
  const res = await axios.post(
    "https://www.talesoffinance.com/api/login",
    body
  );
  return res;
};

export const useUserLogin = () => useMutation(userLogin);

//   <------------------ polling api ------------------->
const getPollings = async (id) => {
  const res = await axiosInstance.get(endpoints.polling);
  return res;
};

export const usePolling = (onSuccess = () => {}, onError = () => {}) =>
  useQuery(["POLLING"], getPollings, {
    onSuccess,
    onError,
    refetchInterval: 5000,
  });
