import React, { useEffect, useMemo } from "react";
import IndustryCard from "../../../components/listing-cards/industry-card";
import PostTypeNavBar from "../../../components/post-type-nav-bar";
import { useLocation } from "react-router-dom";
import { useIndustryList } from "../../../react-query/query-hooks/industryQuery.hooks";
import Loader from "../../../components/loader";

const tabItems = [
  { name: "Create", route: "industry/create" },
  { name: "Published", route: "industry/published" },
  { name: "Draft", route: "industry/draft" },
  { name: "Trash", route: "industry/trash" },
];

const ListingIndustry = () => {
  const { pathname } = useLocation();
  const pageTypeHandler = useMemo(() => {
    if (pathname?.includes("published")) {
      return 1;
    } else if (pathname?.includes("draft")) {
      return 2;
    } else {
      return 3;
    }
  }, [pathname]);
  const {
    data: industryListing,
    isLoading,
    refetch,
  } = useIndustryList(pageTypeHandler);
  useEffect(() => {
    refetch();
  }, [pathname]);

  console.log("industryListing", industryListing);
  return (
    <>
      <PostTypeNavBar tabItems={tabItems} />
      {!isLoading ? (
        <div className="container grid grid-cols-1 md:grid-cols-2 gap-4 mx-auto p-8 m-10 ">
          {!!industryListing && industryListing?.length > 0 ? (
            industryListing?.map((items) => (
                <IndustryCard
                  keys={items?.id}
                  items={items}
                  type={pageTypeHandler}
                />
            ))
          ) : (
            <p>
              You have no{" "}
              {pageTypeHandler == 1
                ? "published"
                : pageTypeHandler == 2
                ? "draft"
                : "trash"}{" "}
              infographic
            </p>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ListingIndustry;
