import { useMutation, useQuery } from "react-query";
import { endpoints } from "../../api/api-end-points";
import axiosInstance from "../../api/axios-instance";
import { TOS, TOS_LIST } from "../query-keys/tosQuery.keys";
import axios from "axios";

// //   <------------------UPDATE TOS CREATE ------------------->
const createTos = async (body) => {
    const res = await axios.post(
      "https://www.talesoffinance.com/api/create-tos",
      body,
      {
        headers: {
          "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
        },
      }
    );
    return res;
  };
// const createTos = async (body) => {
//     const res = await axiosInstance.post(endpoints.tos.create, body);
//     return res;
//   };
  export const useUCreateTos = () => useMutation(createTos);
// //   <------------------UPDATE TOS CREATE ------------------->
const updateTos = async (body) => {
    const res = await axios.post(
      "https://www.talesoffinance.com/api/update-tos",
      body,
      {
        headers: {
          "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
        },
      }
    );
    return res;
  };
// const createTos = async (body) => {
//     const res = await axiosInstance.post(endpoints.tos.create, body);
//     return res;
//   };
  export const useUpdateTos = () => useMutation(updateTos);

// //   <------------------ GET TOS LIST BY STATUS ------------------->

const getTOFList = async (status) => {
    const res = await axiosInstance.get(
      `${endpoints.tos.list}${
        status ? `?status=${status}` : ""
      }`
    );
    return res;
  };
  
  export const useTosList = (
    status,
    enabled = false,
    onSuccess = () => {},
    onError = () => {}
  ) =>
    useQuery([TOS_LIST, status], () => getTOFList(status), {
      onSuccess,
      onError,
      enabled,
      select: (data) => data?.data?.tosLists ?? [],
    });

// //   <------------------UPDATE TOS CREATE ------------------->
const updateStatusTos = async (body) => {
    const res = await axiosInstance.post(endpoints.tos.updateStatus, body);
    return res;
  };
  export const useUpdateStatusTos = () => useMutation(updateStatusTos);


  // //   <------------------ GET TOS details BY STATUS ------------------->

const getTOF = async (id) => {
    const res = await axiosInstance.get(
      `${endpoints.tos.details}${
        !!id ? `/${id}` : ""
      }`
    );
    return res;
  };
  
  export const useTos = (
    id,
    enabled = false,
    onSuccess = () => {},
    onError = () => {}
  ) =>
    useQuery([TOS, id], () => getTOF(id), {
      onSuccess,
      onError,
      enabled,
      select: (data) => data?.data?.tos ?? [],
    });