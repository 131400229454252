import React, { useState } from "react";
import { useUserLogin } from "../../../react-query/query-hooks/loginQuery.hooks";
import { AES, enc } from "crypto-js";
import useNotiStack from "../../../custom-hooks/useNotiStack";
import useRedirect from "../../../custom-hooks/useRedirect";

const CmsLogin = () => {
  const { toastSuccess, toastError } = useNotiStack();
  const redirectTo = useRedirect();
  const [isSignUp, setIsSignUp] = useState(false);
  const [userDetails, setUserDetails] = useState({ email: "", password: "" });
  const { mutate: logIn } = useUserLogin();
  const getUserData = (field, value) => {
    setUserDetails({ ...userDetails, [field]: value });
  };
  const getUserLogin = () =>
    logIn(userDetails, {
      onSuccess: (response) => {
        const { token, message } = response?.data ?? {};
        const bytes = AES.decrypt(token, "tails-of-finance");
        const userToken = bytes.toString(enc.Utf8);
        toastSuccess(message ?? "Login successful");
        console.log("userToken", userToken);
        if (userToken == "true") {
          localStorage.setItem("token", token);
          redirectTo('blogs/create')
        }
      },
    });
  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign {isSignUp ? "up" : "in"} to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900 text-left"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                onChange={(e) => getUserData("email", e.target.value)}
                autoComplete="email"
                required
                className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <>
            <div className="mt-4">
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900 text-left"
                >
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={(e) => getUserData("password", e.target.value)}
                  autoComplete="current-password"
                  required
                  className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900 text-left"
                >
                  Confirm password
                </label>
              </div>
            </div>
          </>

          <div className="mt-4">
            <button
              type="submit"
              onClick={getUserLogin}
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Sign in
              {/* {isSignUp ? "Sign up" : "Sign in"} */}
            </button>
          </div>

          {/* <p className="mt-10 text-center text-sm text-gray-500 cursor-pointer">
            {isSignUp ? "Already" : "Not"} a member?{" "}
            <a
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
              onClick={userLoginHandler}
            >
              {isSignUp ? "Sign in" : "Sign up"}
            </a>
          </p> */}
        </div>
      </div>
    </>
  );
};

export default CmsLogin;
