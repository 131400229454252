import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import {
  useTos,
  useUCreateTos,
  useUpdateTos,
} from "../../../react-query/query-hooks/tosQuery.hooks";
import PostTypeNavBar from "../../../components/post-type-nav-bar";
import { useLocation } from "react-router-dom";
import { useIndustryNameList } from "../../../react-query/query-hooks/industryQuery.hooks";
import useNotiStack from "../../../custom-hooks/useNotiStack";
import useRedirect from "../../../custom-hooks/useRedirect";

const tabItems = [
  { name: "Create", route: "tales-of-startups/create" },
  { name: "Published", route: "tales-of-startups/published" },
  { name: "Draft", route: "tales-of-startups/draft" },
  { name: "Trash", route: "tales-of-startups/trash" },
];
const indianStates = [
  { label: "Andhra Pradesh", value: "Andhra Pradesh" },
  { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
  { label: "Assam", value: "Assam" },
  { label: "Bihar", value: "Bihar" },
  { label: "Chhattisgarh", value: "Chhattisgarh" },
  { label: "Goa", value: "Goa" },
  { label: "Gujarat", value: "Gujarat" },
  { label: "Haryana", value: "Haryana" },
  { label: "Himachal Pradesh", value: "Himachal Pradesh" },
  { label: "Jharkhand", value: "Jharkhand" },
  { label: "Karnataka", value: "Karnataka" },
  { label: "Kerala", value: "Kerala" },
  { label: "Madhya Pradesh", value: "Madhya Pradesh" },
  { label: "Maharashtra", value: "Maharashtra" },
  { label: "Manipur", value: "Manipur" },
  { label: "Meghalaya", value: "Meghalaya" },
  { label: "Mizoram", value: "Mizoram" },
  { label: "Nagaland", value: "Nagaland" },
  { label: "Odisha", value: "Odisha" },
  { label: "Punjab", value: "Punjab" },
  { label: "Rajasthan", value: "Rajasthan" },
  { label: "Sikkim", value: "Sikkim" },
  { label: "Tamil Nadu", value: "Tamil Nadu" },
  { label: "Telangana", value: "Telangana" },
  { label: "Tripura", value: "Tripura" },
  { label: "Uttar Pradesh", value: "Uttar Pradesh" },
  { label: "Uttarakhand", value: "Uttarakhand" },
  { label: "West Bengal", value: "West Bengal" },
  {
    label: "Andaman and Nicobar Islands",
    value: "Andaman and Nicobar Islands",
  },
  { label: "Chandigarh", value: "Chandigarh" },
  {
    label: "Dadra and Nagar Haveli and Daman and Diu",
    value: "Dadra and Nagar Haveli and Daman and Diu",
  },
  { label: "Lakshadweep", value: "Lakshadweep" },
  { label: "Delhi", value: "Delhi" },
  { label: "Puducherry", value: "Puducherry" },
];
const businessModelList = [
  { label: "B2B", value: "B2B" },
  { label: "B2C", value: "B2C" },
  { label: "B2G", value: "B2G" },
  { label: "C2C", value: "C2C" },
];
const employeeHeadcount = [
  { label: "<10", value: "<10" },
  { label: "11 to 20", value: "11 to 20" },
  { label: "21 to 40", value: "21 to 40" },
  { label: "41 to 60", value: "41 to 60" },
  { label: "61 to 100", value: "61 to 100" },
  { label: "101 to 150", value: "101 to 150" },
  { label: "151 to 250", value: "151 to 250" },
  { label: "251 to 500", value: "251 to 500" },
  { label: "501 to 1000", value: "501 to 1000" },
  { label: "1000+", value: "1000+" },
];
const revenueStreamList = [
  { label: "E-commerce product", value: "E-commerce product" },
  { label: "Fee-for-service", value: "Fee-for-service" },
  { label: "Freemium, subscription", value: "Freemium, subscription" },
  {
    label: "Advertising, commission on transactions, Freemium",
    value: "Advertising, commission on transactions, Freemium",
  },
  { label: "Commission on transaction", value: "Commission on transaction" },
  {
    label: "Licensing, commission on transaction",
    value: "Licensing, commission on transaction",
  },
  { label: "Subscription", value: "Subscription" },
  {
    label: "Commission on transaction, Freemium, Subscription",
    value: "Commission on transaction, Freemium, Subscription",
  },
  {
    label: "Licensing, Subscription, Fee-for-service",
    value: "Licensing, Subscription, Fee-for-service",
  },
  {
    label: "Advertising, Freemium, Lending, commission on transaction",
    value: "Advertising, Freemium, Lending, commission on transaction",
  },
  {
    label: "Freemium, Subscription, E-commerce - product, E-commerce - service",
    value: "Freemium, Subscription, E-commerce - product, E-commerce - service",
  },
  {
    label: "Advertising, commission on transaction, Fee-for-service",
    value: "Advertising, commission on transaction, Fee-for-service",
  },
  { label: "Commission on transaction", value: "Commission on transaction" },
  {
    label: "Commission on transaction, Lending",
    value: "Commission on transaction, Lending",
  },
  { label: "other", value: "other" },
  {
    label: "Fee-for-service, Commission on transaction, Subscription",
    value: "Fee-for-service, Commission on transaction, Subscription",
  },
  {
    label: "E-commerce - product, Fee-for-service",
    value: "E-commerce - product, Fee-for-service",
  },
  {
    label: "Commission on transaction, E-commerce  - service",
    value: "Commission on transaction, E-commerce  - service",
  },
  { label: "Freemium, Fee-For-Service", value: "Freemium, Fee-For-Service" },
  {
    label: "Commission on transaction, Fee-for-services",
    value: "Commission on transaction, Fee-for-services",
  },
  {
    label: "E-commerce  - Service, Subscription, Fee-for-service",
    value: "E-commerce  - Service, Subscription, Fee-for-service",
  },
  {
    label: "Subscription, Fee-for-service",
    value: "Subscription, Fee-for-service",
  },
  {
    label: "Advertising, Commission on transaction, Sponsorship",
    value: "Advertising, Commission on transaction, Sponsorship",
  },
  { label: "Fee-for-service", value: "Fee-for-service" },
  {
    label: "Advertising, Fee-for-service",
    value: "Advertising, Fee-for-service",
  },
  { label: "E-commerce - Service", value: "E-commerce - Service" },
  { label: "Selling data, Subscription", value: "Selling data, Subscription" },
  {
    label: "Commission on transaction, Subscription, Fee-for-service",
    value: "Commission on transaction, Subscription, Fee-for-service",
  },
  { label: "Interest, Lending", value: "Interest, Lending" },
  {
    label: "Commission on transaction, Lending, Fee-for-service",
    value: "Commission on transaction, Lending, Fee-for-service",
  },
  { label: "E-commerce  - Service", value: "E-commerce  - Service" },
  { label: "Renting/Leasing", value: "Renting/Leasing" },
  {
    label: "Fee-for-service, Commission on transaction",
    value: "Fee-for-service, Commission on transaction",
  },
  {
    label: "E-commerce - product, E-commerce - Service, Lending",
    value: "E-commerce - product, E-commerce - Service, Lending",
  },
  {
    label:
      "Commission on transaction, E-commerce - service, Interest, Fee-for-service",
    value:
      "Commission on transaction, E-commerce - service, Interest, Fee-for-service",
  },
  { label: "Advertising", value: "Advertising" },
  {
    label: "Commission on transaction, Lending",
    value: "Commission on transaction, Lending",
  },
  { label: "Commissions on transaction", value: "Commissions on transaction" },
  {
    label: "E-commerce - service, Fee-for-Service",
    value: "E-commerce - service, Fee-for-Service",
  },
  {
    label: "Commission on transaction, Fee-for-service",
    value: "Commission on transaction, Fee-for-service",
  },
  {
    label: "Advertising, Affliate Marketing",
    value: "Advertising, Affliate Marketing",
  },
  {
    label: "Commission on transaction, Interest, Lending",
    value: "Commission on transaction, Interest, Lending",
  },
  {
    label: "E-commerce product, E-commerce service",
    value: "E-commerce product, E-commerce service",
  },
  { label: "Interest, Lending", value: "Interest, Lending" },
  {
    label:
      "E-commerce - product, E-commerce - service, Subscription, Fee-for-service",
    value:
      "E-commerce - product, E-commerce - service, Subscription, Fee-for-service",
  },
  {
    label: "Advertising, Subscription, Sponsorship, Affiliate Marketing",
    value: "Advertising, Subscription, Sponsorship, Affiliate Marketing",
  },
  {
    label: "E-commerce product, Lending",
    value: "E-commerce product, Lending",
  },
  {
    label: "Affiliate Marketing, Commission on transaction, E-commerce Service",
    value: "Affiliate Marketing, Commission on transaction, E-commerce Service",
  },
  {
    label: "Commission on transaction, Interest",
    value: "Commission on transaction, Interest",
  },
  { label: "Lending", value: "Lending" },
  { label: "Selling data", value: "Selling data" },
  {
    label: "Donations, Renting/Leasing, Subscription",
    value: "Donations, Renting/Leasing, Subscription",
  },
  { label: "Freemium", value: "Freemium" },
  {
    label: "Commission on transaction, E-commerce product, Lending",
    value: "Commission on transaction, E-commerce product, Lending",
  },
  {
    label: "Freemium, E-commerce service",
    value: "Freemium, E-commerce service",
  },
  {
    label:
      "E-commerce - product, E-commerce - service, Fee-for-service, Subscription",
    value:
      "E-commerce - product, E-commerce - service, Fee-for-service, Subscription",
  },
  {
    label: "Commission on transaction, Interest, Lending, Fee-for-service",
    value: "Commission on transaction, Interest, Lending, Fee-for-service",
  },
  { label: "Renting/Leasing", value: "Renting/Leasing" },
  {
    label: "E-commerce service, Subscription",
    value: "E-commerce service, Subscription",
  },
  {
    label: "Interest, Lending, Subscription",
    value: "Interest, Lending, Subscription",
  },
  {
    label:
      "Commission on transaction, Subscription, Physical commerce, Fee-for-service",
    value:
      "Commission on transaction, Subscription, Physical commerce, Fee-for-service",
  },
  {
    label: "E-commerce -Service, Fee-for-service, Licensing, Subscription",
    value: "E-commerce -Service, Fee-for-service, Licensing, Subscription",
  },
  {
    label: "Interest, Subscription, Lending",
    value: "Interest, Subscription, Lending",
  },
];

const optionsList = [
  { label: "Founder Details", isSelected: false, list: [] },
  { label: "Revenue", isSelected: false, list: [] },
  { label: "Target Group", isSelected: false, list: [] },
  { label: "Total Fundings(in Milllion)", isSelected: false, list: [] },
  { label: "Investor", isSelected: false, list: [] },
  { label: "Round", isSelected: false, list: [] },
];
const TalesOfStartUps = () => {
  const { pathname } = useLocation();
  const { toastSuccess, toastError } = useNotiStack();
  const redirectTo = useRedirect();
  const [coverImg, setCoverImg] = useState(null);
  const [optionalFormList, setOptionalFormList] = useState(optionsList);
  const [generalInfo, setGeneralInfo] = useState({});
  const [tosID, setTosId] = useState("");
  const getCoverImage = (img) => setCoverImg(img);
  const removeImage = () => setCoverImg(null);
  const optFormListShower = useMemo(
    () => optionalFormList?.filter((_opt) => !_opt?.isSelected),
    [optionalFormList]
  );

  const { mutate: createTos, isLoading } = useUCreateTos();
  const { mutate: updateTos, isLoading: updateLoader } = useUpdateTos();
  const {
    data: tosDetails,
    isLoading: tosDetailsLoader,
    refetch,
  } = useTos(pathname?.split("/")?.at(-1), false, (response) => {
    const {
      id,
      startup_name,
      website_link,
      legal_name,
      industry,
      based_out_of,
      business_model,
      founded_in,
      employee_headcount,
      profile_summary,
      options,
      status,
      cover_photo,
    } = response ?? {};
    setGeneralInfo({
      startup_name,
      website_link,
      legal_name,
      industry: JSON.parse(industry),
      based_out_of: JSON.parse(based_out_of),
      business_model: JSON.parse(business_model),
      founded_in,
      employee_headcount: JSON.parse(employee_headcount),
      profile_summary,
    });
    setCoverImg(cover_photo);
    setOptionalFormList(JSON.parse(options) ?? {});
    // console.log('parse',JSON.parse(options));
  });

  const {
    data: industryNameList,
    isLoading: industryNameListLoader,
    refetch: fetchIndustryList,
  } = useIndustryNameList();

  const getGenInfo = (e, type) => {
    let fieldName = "";
    let value = "";
    if (Array.isArray(e)) {
      value = e;
      if (type == "industry") {
        fieldName = "industry";
      } else if (type == "based_out_of") {
        fieldName = "based_out_of";
      } else if (type == "business_model") {
        fieldName = "business_model";
      } else if (type == "employee_headcount") {
        fieldName = "employee_headcount";
      }
      console.log("getUserGivenData", e);
    } else {
      fieldName = e.target.name;
      value = e.target.value;
    }
    const genInfo = { ...generalInfo, [fieldName]: value };
    setGeneralInfo(genInfo);
  };
  const addPostHandler = (type = "publish") => {
    const {
      startup_name,
      website_link,
      legal_name,
      industry,
      based_out_of,
      business_model,
      founded_in,
      employee_headcount,
      profile_summary,
    } = generalInfo ?? {};
    const redirectionUrl =
      tosDetails?.status == "1"
        ? "tales-of-startups/published"
        : tosDetails?.status == "2"
        ? "tales-of-startups/draft"
        : "tales-of-startups/trash";
    const formData = new FormData();
    if (!!tosID) {
      if (type == "publish") {
        formData.append("id", tosID);
        formData.append("website_link", website_link);
        formData.append("legal_name", legal_name);
        formData.append("industry", JSON.stringify(industry));
        formData.append("based_out_of", JSON.stringify(based_out_of));
        formData.append("business_model", JSON.stringify(business_model));
        formData.append("founded_in", founded_in);
        formData.append(
          "employee_headcount",
          JSON.stringify(employee_headcount)
        );
        formData.append("profile_summary", profile_summary);
        formData.append("options", JSON.stringify(optionalFormList));
        formData.append("cover_photo", coverImg);
        updateTos(formData, {
          onSuccess: () => {
            toastSuccess("Tales of Startup updated successfully");
            redirectTo(redirectionUrl);
          },
          onError: () => {
            toastError("Something went wrong please try again later.");
          },
        });
      } else {
        setGeneralInfo({});
        setCoverImg(null);
        // setIsPreview(false);
        redirectTo("tales-of-startups/create");
      }
    } else {
      formData.append("startup_name", startup_name);
      formData.append("website_link", website_link);
      formData.append("legal_name", legal_name);
      formData.append("industry", JSON.stringify(industry));
      formData.append("based_out_of", JSON.stringify(based_out_of));
      formData.append("business_model", JSON.stringify(business_model));
      formData.append("founded_in", founded_in);
      formData.append("employee_headcount", JSON.stringify(employee_headcount));
      formData.append("profile_summary", profile_summary);
      formData.append("options", JSON.stringify(optionalFormList));
      formData.append("cover_photo", coverImg);
      formData.append("status", type == "publish" ? 1 : 2);
      createTos(formData, {
        onSuccess: () => {
          toastSuccess("Tales of Startup created successfully");
          redirectTo(
            type == "publish"
              ? "tales-of-startups/published"
              : "tales-of-startups/draft"
          );
        },
        onError: () => {
          toastError("Something went wrong please try again later.");
        },
      });
    }
  };
  const selectedOptForm = (opt) => {
    console.log("opt", opt);
    const optForm = () => {
      switch (opt) {
        case "Founder Details":
          return { team_member: "", position: "", profile_ink: "" };
        case "Revenue":
          return { revenue_stream: "" };
        case "Target Group":
          return {
            customer_group: "",
            client_business_structure: "",
            geographical_segment: "",
            age_group: "",
            income_bracket: "",
            user_belongs_to: "",
          };
        case "Total Fundings(in Milllion)":
          return { total_fundings: "" };
        case "Investor":
          return { funded_by: "" };
        case "Round":
          return { date: "", funds_raised: "", name_of_investors: "" };
        default:
          return {};
      }
    };
    const optListFilter = optionalFormList?.map((_opt) => {
      const optionList = [{}];
      if (_opt?.label == opt) {
        return { ..._opt, isSelected: true, list: [{ id: 0, ...optForm() }] };
      } else {
        return { ..._opt };
      }
    });
    setOptionalFormList(optListFilter);
  };
  const deleteOption = (opt) => {
    const optListFilter = optionalFormList?.map((_opt) => {
      if (_opt?.label == opt) {
        return { ..._opt, isSelected: false, list: [] };
      } else {
        return { ..._opt };
      }
    });
    setOptionalFormList(optListFilter);
  };
  const addMoreOptForm = (opt) => {
    const optForm = () => {
      switch (opt) {
        case "Founder Details":
          return { team_member: "", position: "", profile_ink: "" };
        case "Revenue":
          return { revenue_stream: "" };
        case "Target Group":
          return {
            customer_group: "",
            client_business_structure: "",
            geographical_segment: "",
            age_group: "",
            income_bracket: "",
            user_belongs_to: "",
          };
        case "Total Fundings(in Milllion)":
          return { total_fundings: "" };
        case "Investor":
          return { funded_by: "" };
        case "Round":
          return { date: "", funds_raised: "", name_of_investors: "" };
        default:
          return {};
      }
    };
    const updatedOptList = optionalFormList?.map((_opt) => {
      if (_opt?.label == opt) {
        return {
          ..._opt,
          list: [..._opt?.list, { id: _opt?.list?.length, ...optForm() }],
        };
      } else {
        return { ..._opt };
      }
    });
    setOptionalFormList(updatedOptList);
  };
  const deleteMoreOptForm = (id, label) => {
    const updatedOptList = optionalFormList?.map((_opt) => {
      if (_opt?.label == label) {
        return {
          ..._opt,
          list: _opt?.list?.filter((_i) => _i?.id != id),
        };
      } else {
        return { ..._opt };
      }
    });
    setOptionalFormList(updatedOptList);
  };
  const getUserGivenData = (e, id, label) => {
    let fieldName = "";
    let value = "";
    if (Array.isArray(e)) {
      value = e;
      if (label == "Revenue") {
        fieldName = "revenue_stream";
      } else if (label == "Total Fundings(in Milllion)") {
        fieldName = "total_fundings";
      } else if (label == "Investor") {
        fieldName = "funded_by";
      }
      console.log("getUserGivenData", e);
    } else {
      fieldName = e.target.name;
      value = e.target.value;
    }
    const updatedOptList = optionalFormList?.map((_opt) => {
      if (_opt?.label == label) {
        return {
          ..._opt,
          list: _opt?.list?.map((_i) => {
            if (_i?.id == id) {
              return { ..._i, [fieldName]: value };
            } else {
              return { ..._i };
            }
          }),
        };
      } else {
        return { ..._opt };
      }
    });
    setOptionalFormList(updatedOptList);
  };
  const optionalListHandler = (optionType) => {
    console.log("optionType", optionType);
    const { label, list } = optionType ?? {};
    switch (label) {
      case "Founder Details":
        return (
          <div className="rounded-lg m-2 md:m-8 p-4 bg-gradient-to-r from-green-50 to-cyan-200">
            <div className="flex justify-between">
              <h2 className="text-3xl text-gray-700 font-bold mb-5 text-left">
                {label}
              </h2>
              <button onClick={() => deleteOption(label)}>
                <svg
                  xmlns="http:www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  className="fill-red-600"
                >
                  <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                </svg>
              </button>
            </div>
            {list?.length > 0 &&
              list?.map((_li) => (
                <div className="flex flex-wrap justify-start gap-4 mb-4">
                  <label class="grow block">
                    <span class="block text-sm font-medium text-slate-700 text-left">
                      Team Member
                    </span>
                    <input
                      type="text"
                      name="team_member"
                      value={_li?.team_member}
                      onChange={(e) => getUserGivenData(e, _li?.id, label)}
                      class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                      placeholder="Ex: BYJUS"
                    />
                  </label>
                  <label class="grow block">
                    <span class="block text-sm font-medium text-slate-700 text-left">
                      Position
                    </span>
                    <input
                      type="text"
                      name="position"
                      value={_li?.position}
                      onChange={(e) => getUserGivenData(e, _li?.id, label)}
                      class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                      placeholder="Ex: Jhon Doe"
                    />
                  </label>
                  <label class="grow block">
                    <span class="block text-sm font-medium text-slate-700 text-left">
                      Profile Link
                    </span>
                    <input
                      type="text"
                      name="profile_ink"
                      value={_li?.profile_ink}
                      onChange={(e) => getUserGivenData(e, _li?.id, label)}
                      class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                      placeholder="Ex: www.xyz.com"
                    />
                  </label>
                  {list?.length > 1 && (
                    <button onClick={() => deleteMoreOptForm(_li?.id, label)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        className="fill-red-600"
                      >
                        <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                      </svg>
                    </button>
                  )}
                </div>
              ))}
            <div className="flex justify-end gap-4">
              {/* {list?.length > 1 && (
                <button
                // onClick={() => deleteInvestor(idx)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    className="fill-red-600"
                  >
                    <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                  </svg>
                </button>
              )} */}
              <button
                className="rounded-md bg-blue-500 p-1 px-2 text-white"
                onClick={() => addMoreOptForm(label)}
              >
                Add more
              </button>
            </div>
          </div>
        );

      case "Revenue":
        return (
          <div className="rounded-lg m-2 md:m-8 p-4 bg-gradient-to-r from-green-50 to-cyan-200">
            <div className="flex justify-between">
              <h2 className="text-3xl text-gray-700 font-bold mb-5 text-left">
                {label}
              </h2>
              <button onClick={() => deleteOption(label)}>
                <svg
                  xmlns="http:www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  className="fill-red-600"
                >
                  <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                </svg>
              </button>
            </div>
            {list?.length > 0 &&
              list?.map((_li) => (
                <div className="flex flex-wrap justify-start gap-4 mb-4">
                  <label class="grow block">
                    <span class="block text-sm font-medium text-slate-700 text-left">
                      Revenue Stream
                    </span>
                    <Select
                      isMulti
                      name="revenue_stream"
                      value={_li?.revenue_stream}
                      onChange={(e) => getUserGivenData(e, _li?.id, label)}
                      placeholder={
                        <div className="text-left">
                          Please select revenue Stream
                        </div>
                      }
                      options={revenueStreamList}
                      className="basic-multi-select w-full mt-1 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block rounded-md sm:text-sm focus:ring-1 text-left"
                      classNamePrefix="select"
                      menuPlacement="bottom"
                    />
                  </label>
                  {list?.length > 1 && (
                    <button onClick={() => deleteMoreOptForm(_li?.id, label)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        className="fill-red-600"
                      >
                        <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                      </svg>
                    </button>
                  )}
                </div>
              ))}
            <div className="flex justify-end gap-4">
              {/* {list?.length > 1 && (
                <button
                // onClick={() => deleteInvestor(idx)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    className="fill-red-600"
                  >
                    <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                  </svg>
                </button>
              )} */}
              <button
                className="rounded-md bg-blue-500 p-1 px-2 text-white"
                onClick={() => addMoreOptForm(label)}
              >
                Add more
              </button>
            </div>
          </div>
        );

      case "Target Group":
        return (
          <div className="rounded-lg m-2 md:m-8 p-4 bg-gradient-to-r from-green-50 to-cyan-200">
            <div className="flex justify-between">
              <h2 className="text-3xl text-gray-700 font-bold mb-5 text-left">
                {label}
              </h2>
              <button onClick={() => deleteOption(label)}>
                <svg
                  xmlns="http:www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  className="fill-red-600"
                >
                  <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                </svg>
              </button>
            </div>
            {list?.length > 0 &&
              list?.map((_li) => (
                <>
                  <div className="flex flex-wrap justify-start gap-4 mb-4">
                    <label class="grow block">
                      <span class="block text-sm font-medium text-slate-700 text-left">
                        Customer Group
                      </span>
                      <input
                        type="text"
                        name="customer_group"
                        value={_li?.customer_group}
                        onChange={(e) => getUserGivenData(e, _li?.id, label)}
                        class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Ex: BYJUS"
                      />
                    </label>
                    <label class="grow block">
                      <span class="block text-sm font-medium text-slate-700 text-left">
                        Client Business Structure
                      </span>
                      <input
                        type="text"
                        name="client_business_structure"
                        value={_li?.client_business_structure}
                        onChange={(e) => getUserGivenData(e, _li?.id, label)}
                        class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Ex: Jhon Doe"
                      />
                    </label>
                    <label class="grow block">
                      <span class="block text-sm font-medium text-slate-700 text-left">
                        Geographical Segment
                      </span>
                      <input
                        type="text"
                        name="geographical_segment"
                        value={_li?.geographical_segment}
                        onChange={(e) => getUserGivenData(e, _li?.id, label)}
                        class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Ex: www.xyz.com"
                      />
                    </label>
                    {list?.length > 1 && (
                      <button onClick={() => deleteMoreOptForm(_li?.id, label)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          className="fill-red-600"
                        >
                          <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                        </svg>
                      </button>
                    )}
                  </div>
                  <div className="flex flex-wrap justify-start gap-4 mb-4">
                    <label class="grow block">
                      <span class="block text-sm font-medium text-slate-700 text-left">
                        Age Group
                      </span>
                      <input
                        type="text"
                        name="age_group"
                        value={_li?.age_group}
                        onChange={(e) => getUserGivenData(e, _li?.id, label)}
                        class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Ex: BYJUS"
                      />
                    </label>
                    <label class="grow block">
                      <span class="block text-sm font-medium text-slate-700 text-left">
                        Income Bracket
                      </span>
                      <input
                        type="text"
                        name="income_bracket"
                        value={_li?.income_bracket}
                        onChange={(e) => getUserGivenData(e, _li?.id, label)}
                        class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Ex: Jhon Doe"
                      />
                    </label>
                    <label class="grow block">
                      <span class="block text-sm font-medium text-slate-700 text-left">
                        User Belongs to
                      </span>
                      <input
                        type="text"
                        name="user_belongs_to"
                        value={_li?.user_belongs_to}
                        onChange={(e) => getUserGivenData(e, _li?.id, label)}
                        class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Ex: www.xyz.com"
                      />
                    </label>
                  </div>
                </>
              ))}
            <div className="flex justify-end gap-4">
              {/* {list?.length > 1 && (
                <button
                // onClick={() => deleteInvestor(idx)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    className="fill-red-600"
                  >
                    <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                  </svg>
                </button>
              )} */}
              <button
                className="rounded-md bg-blue-500 p-1 px-2 text-white"
                onClick={() => addMoreOptForm(label)}
              >
                Add more
              </button>
            </div>
          </div>
        );

      case "Total Fundings(in Milllion)":
        return (
          <div className="rounded-lg m-2 md:m-8 p-4 bg-gradient-to-r from-green-50 to-cyan-200">
            <div className="flex justify-between">
              <h2 className="text-3xl text-gray-700 font-bold mb-5 text-left">
                {label}
              </h2>
              <button onClick={() => deleteOption(label)}>
                <svg
                  xmlns="http:www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  className="fill-red-600"
                >
                  <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                </svg>
              </button>
            </div>
            {list?.length > 0 &&
              list?.map((_li) => (
                <>
                  <div className="flex flex-wrap justify-start gap-4 mb-4">
                    <label class="grow block">
                      <span class="block text-sm font-medium text-slate-700 text-left">
                        Total Fundings
                      </span>
                      <input
                        type="text"
                        name="total_fundings"
                        value={_li?.total_fundings}
                        onChange={(e) => getUserGivenData(e, _li?.id, label)}
                        class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Ex: BYJUS"
                      />
                    </label>
                    {list?.length > 1 && (
                      <button onClick={() => deleteMoreOptForm(_li?.id, label)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          className="fill-red-600"
                        >
                          <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                        </svg>
                      </button>
                    )}
                  </div>
                </>
              ))}
            <div className="flex justify-end gap-4">
              {/* {list?.length > 1 && (
                <button
                // onClick={() => deleteInvestor(idx)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    className="fill-red-600"
                  >
                    <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                  </svg>
                </button>
              )} */}
              <button
                className="rounded-md bg-blue-500 p-1 px-2 text-white"
                onClick={() => addMoreOptForm(label)}
              >
                Add more
              </button>
            </div>
          </div>
        );

      case "Investor":
        return (
          <div className="rounded-lg m-2 md:m-8 p-4 bg-gradient-to-r from-green-50 to-cyan-200">
            <div className="flex justify-between">
              <h2 className="text-3xl text-gray-700 font-bold mb-5 text-left">
                {label}
              </h2>
              <button onClick={() => deleteOption(label)}>
                <svg
                  xmlns="http:www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  className="fill-red-600"
                >
                  <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                </svg>
              </button>
            </div>
            {list?.length > 0 &&
              list?.map((_li) => (
                <>
                  <div className="flex flex-wrap justify-start gap-4 mb-4">
                    <label class="grow block">
                      <span class="block text-sm font-medium text-slate-700 text-left">
                        Funded By
                      </span>
                      <input
                        type="text"
                        name="funded_by"
                        value={_li?.funded_by}
                        onChange={(e) => getUserGivenData(e, _li?.id, label)}
                        class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Ex: BYJUS"
                      />
                    </label>
                    {list?.length > 1 && (
                      <button onClick={() => deleteMoreOptForm(_li?.id, label)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          className="fill-red-600"
                        >
                          <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                        </svg>
                      </button>
                    )}
                  </div>
                </>
              ))}
            <div className="flex justify-end gap-4">
              {/* {list?.length > 1 && (
                <button
                // onClick={() => deleteInvestor(idx)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    className="fill-red-600"
                  >
                    <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                  </svg>
                </button>
              )} */}
              <button
                className="rounded-md bg-blue-500 p-1 px-2 text-white"
                onClick={() => addMoreOptForm(label)}
              >
                Add more
              </button>
            </div>
          </div>
        );

      case "Round":
        return (
          <div className="rounded-lg m-2 md:m-8 p-4 bg-gradient-to-r from-green-50 to-cyan-200">
            <div className="flex justify-between">
              <h2 className="text-3xl text-gray-700 font-bold mb-5 text-left">
                {label}
              </h2>
              <button onClick={() => deleteOption(label)}>
                <svg
                  xmlns="http:www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  className="fill-red-600"
                >
                  <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                </svg>
              </button>
            </div>
            {list?.length > 0 &&
              list?.map((_li, index) => (
                <>
                  <p className="text-start font-bold textlg">
                    {label}-{index + 1}
                  </p>
                  <div className="flex flex-wrap justify-start gap-4 mb-4">
                    <label class="grow block">
                      <span class="block text-sm font-medium text-slate-700 text-left">
                        Date
                      </span>
                      <input
                        type="text"
                        name="date"
                        value={_li?.date}
                        onChange={(e) => getUserGivenData(e, _li?.id, label)}
                        class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Ex: BYJUS"
                      />
                    </label>
                    <label class="grow block">
                      <span class="block text-sm font-medium text-slate-700 text-left">
                        Funds Raised (USD Mns)
                      </span>
                      <input
                        type="text"
                        name="funds_raised"
                        value={_li?.funds_raised}
                        onChange={(e) => getUserGivenData(e, _li?.id, label)}
                        class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Ex: Jhon Doe"
                      />
                    </label>
                    <label class="grow block">
                      <span class="block text-sm font-medium text-slate-700 text-left">
                        Name of Investors
                      </span>
                      <input
                        type="text"
                        name="name_of_investors"
                        value={_li?.name_of_investors}
                        onChange={(e) => getUserGivenData(e, _li?.id, label)}
                        class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Ex: www.xyz.com"
                      />
                    </label>
                    {list?.length > 1 && (
                      <button onClick={() => deleteMoreOptForm(_li?.id, label)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          className="fill-red-600"
                        >
                          <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                        </svg>
                      </button>
                    )}
                  </div>
                </>
              ))}
            <div className="flex justify-end gap-4">
              {/* {list?.length > 1 && (
                <button
                // onClick={() => deleteInvestor(idx)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    className="fill-red-600"
                  >
                    <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                  </svg>
                </button>
              )} */}
              <button
                className="rounded-md bg-blue-500 p-1 px-2 text-white"
                onClick={() => addMoreOptForm(label)}
              >
                Add more
              </button>
            </div>
          </div>
        );

      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (pathname?.includes("update")) {
      let urlPram = pathname?.split("/") ?? [];
      if (urlPram?.length > 0 && parseInt(urlPram?.at(-1)) > 0) {
        setTosId(urlPram?.at(-1));
        refetch();
      }
    }
  }, [pathname]);
  useEffect(() => {
    fetchIndustryList();
  }, []);
  console.log("industryNameList", generalInfo);
  return (
    <>
      <PostTypeNavBar tabItems={tabItems} />
      <div className="flex justify-end gap-4 align-center mt-4 me-4">
        {/* <span
          className={`text-white bg-gradient-to-r ${
            isPreview ? "from-blue-400" : "from-green-400"
          } ${isPreview ? "via-blue-500" : "via-green-500"} ${
            isPreview ? "to-blue-600" : "to-green-600"
          } hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:${
            isPreview ? "ring-blue-300" : "ring-green-300"
          } dark:focus:${
            isPreview ? "ring-blue-800" : "ring-green-800"
          } shadow-lg ${
            isPreview ? "shadow-blue-500/50" : "shadow-green-500/50"
          } dark:shadow-lg dark:${
            isPreview ? "shadow-blue-800/80" : "shadow-green-800/80"
          } font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2`}
          onClick={previewHandler}
        >
          {isPreview ? "Back to edit" : "Preview"}
        </span> */}
      </div>
      {/* {isPreview ? (
        <div className="p-12 md:p-auto">
          <Preview />
        </div>
      ) : ( */}
      <>
        <div className="rounded-lg m-2 md:m-8 p-4 bg-gradient-to-r from-green-50 to-cyan-200">
          <h2 className="text-3xl text-gray-700 font-bold mb-5 text-left">
            General Information
          </h2>
          <div className="flex flex-wrap justify-start gap-4 mb-4">
            <label class="grow block">
              <span class="block text-sm font-medium text-slate-700 text-left">
                Startup name
              </span>
              <input
                type="text"
                name="startup_name"
                value={generalInfo?.startup_name}
                onChange={(e) => getGenInfo(e)}
                class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="Ex: BYJUS"
              />
            </label>
            <label class="grow block">
              <span class="block text-sm font-medium text-slate-700 text-left">
                Website Link
              </span>
              <input
                type="text"
                name="website_link"
                value={generalInfo?.website_link}
                onChange={(e) => getGenInfo(e)}
                class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="Ex: https://xyz.com"
              />
            </label>
            <label class="grow block">
              <span class="block text-sm font-medium text-slate-700 text-left">
                Legally Known As
              </span>
              <input
                type="text"
                name="legal_name"
                value={generalInfo?.legal_name}
                onChange={(e) => getGenInfo(e)}
                class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="Ex: BYJUS"
              />
            </label>
            {!industryNameListLoader && (
              <label class="grow block">
                <span class="block text-sm font-medium text-slate-700 text-left">
                  Industry
                </span>
                <Select
                  isMulti
                  name="industry"
                  value={generalInfo?.industry}
                  onChange={(e) => getGenInfo(e, "industry")}
                  placeholder={
                    <div className="text-left">Please select Industry</div>
                  }
                  options={industryNameList}
                  className="basic-multi-select w-full mt-1 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block rounded-md sm:text-sm focus:ring-1 text-left"
                  classNamePrefix="select"
                  menuPlacement="bottom"
                />
              </label>
            )}
          </div>

          <div className="flex flex-wrap justify-start gap-4 mb-4">
            <label class="grow block">
              <span class="block text-sm font-medium text-slate-700 text-left">
                Based Out Of
              </span>
              <Select
                isMulti
                name="based_out_of"
                value={generalInfo?.based_out_of}
                onChange={(e) => getGenInfo(e, "based_out_of")}
                placeholder={
                  <div className="text-left">Please select State</div>
                }
                options={indianStates}
                className="basic-multi-select w-full mt-1 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block rounded-md sm:text-sm focus:ring-1 text-left"
                classNamePrefix="select"
                menuPlacement="bottom"
              />
            </label>

            <label class="grow block">
              <span class="block text-sm font-medium text-slate-700 text-left">
                Business Model
              </span>
              <Select
                isMulti
                name="business_model"
                value={generalInfo?.business_model}
                onChange={(e) => getGenInfo(e, "business_model")}
                placeholder={
                  <div className="text-left">Please select business model</div>
                }
                options={businessModelList}
                className="basic-multi-select w-full mt-1 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block rounded-md sm:text-sm focus:ring-1 text-left"
                classNamePrefix="select"
                menuPlacement="bottom"
              />
            </label>

            <label class="grow block">
              <span class="block text-sm font-medium text-slate-700 text-left">
                Founded In
              </span>
              <input
                type="text"
                name="founded_in"
                value={generalInfo?.founded_in}
                onChange={(e) => getGenInfo(e)}
                class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="Ex: 2023"
              />
            </label>
            <label class="grow block">
              <span class="block text-sm font-medium text-slate-700 text-left">
                Employee Headcount
              </span>
              <Select
                isMulti
                name="employee_headcount"
                value={generalInfo?.employee_headcount}
                onChange={(e) => getGenInfo(e, "employee_headcount")}
                placeholder={
                  <div className="text-left">
                    Please select employee headcount
                  </div>
                }
                options={employeeHeadcount}
                className="basic-multi-select w-full mt-1 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block rounded-md sm:text-sm focus:ring-1 text-left"
                classNamePrefix="select"
                menuPlacement="bottom"
              />
            </label>
          </div>
          <div class="grid grid-cols-3 gap-4">
            <div className="col-span-2">
              <div className="flex flex-wrap justify-start gap-4 mb-4">
                <label class="grow block">
                  <span class="block text-sm font-medium text-slate-700 text-left">
                    Profile Summary
                  </span>
                  <textarea
                    rows="9"
                    type="text"
                    name="profile_summary"
                    value={generalInfo?.profile_summary}
                    onChange={(e) => getGenInfo(e)}
                    class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 resize-y"
                    placeholder="Enter startup description"
                  />
                </label>
              </div>
            </div>
            {!!coverImg ? (
              <div>
                <img
                  src={
                    typeof coverImg == "string"
                      ? `https://www.talesoffinance.com/tos/${coverImg}`
                      : URL.createObjectURL(coverImg)
                  }
                  //   src={'https://drive.google.com/uc?export=view&id=1CIQgjiF6xXdwXrUcqMW-n6o5vvVQAZI5'}
                  className="object-cover h-56 w-96 rounded-lg border-2 border-slate-950"
                />
                <div className="flex justify-center items-center gap-4 mt-2">
                  {/* <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={changeImage}
          >
            Change Image
          </button> */}
                  <button
                    type="button"
                    class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                    onClick={removeImage}
                  >
                    Remove Image
                  </button>
                </div>
              </div>
            ) : (
              <div className="">
                <label
                  htmlFor="cover-photo"
                  className="block text-sm text-left font-medium leading-6 text-gray-900"
                >
                  Cover photo
                </label>
                <div className="mt-1 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                  <div className="text-center">
                    <svg
                      class="mx-auto h-12 w-12 text-gray-300"
                      viewBox="0 0 24 24"
                      fill="white"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                      >
                        <span className="p-2">Upload a file</span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                          onChange={(e) => getCoverImage(e.target.files[0])}
                        />
                      </label>
                      <p className="pl-1 ps-1">or drag and drop</p>
                    </div>
                    <p className="text-xs leading-5 text-gray-600">
                      PNG, JPG, GIF up to 10MB
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {optionalFormList?.map((_optForm) =>
          _optForm?.isSelected ? <>{optionalListHandler(_optForm)}</> : <></>
        )}
        {optFormListShower?.length > 0 && (
          <div className="mt-1 flex gap-2 justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-4 m-2 md:m-8">
            {optFormListShower?.map((_opt, idx) => (
              <span
                className="relative cursor-pointer rounded-md bg-teal-100 p-4 font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                key={idx + 1}
                onClick={() => selectedOptForm(_opt?.label ?? "")}
              >
                Add {_opt?.label ?? ""}
              </span>
            ))}
          </div>
        )}
        <div className="flex justify-end gap-4 m-2 md:m-8 p-4">
          <button
            className={`rounded-md bg-gray-400 p-1 px-2 text-white`}
            onClick={() => addPostHandler("draft")}
            // disabled={!!blogID ? false : buttonEnabler}
          >
            {!!tosID ? "Discart" : "Save as draft"}
          </button>
          <button
            className={`rounded-md bg-blue-500 p-1 px-2 text-white`}
            onClick={() => addPostHandler("publish")}
            // disabled={buttonEnabler}
          >
            {!!tosID ? "Update" : "Publish"}
          </button>
        </div>
      </>
      {/* )} */}
    </>
  );
};

export default TalesOfStartUps;
